/* Import the custom Montserrat fonts */
@font-face {
  font-family: 'Montserrat-Thin';
  src: url('./fonts/Montserrat-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-ExtraLight';
  src: url('./fonts/Montserrat-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-Light';
  src: url('./fonts/Montserrat-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: url('./fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url('./fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: url('./fonts/Montserrat-Medium.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url('./fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-ExtraBold';
  src: url('./fonts/Montserrat-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-Black';
  src: url('./fonts/Montserrat-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

body,
html {
  margin: 0;
  padding: 0;
  background-color: #f6f6f6;
  font-family: 'Montserrat-SemiBold', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  margin: 15px 0px;
}

h2 {
  margin: 10px 0px;
}

h3 {
  margin: 5px 0px;
}

.ft-1 {
  font-family: 'Montserrat-Thin', sans-serif;
}

.ft-2 {
  font-family: 'Montserrat-ExtraLight', sans-serif;
}

.ft-3 {
  font-family: 'Montserrat-Light', sans-serif;
}

.ft-4 {
  font-family: 'Montserrat-Regular', sans-serif;
}

.ft-5 {
  font-family: 'Montserrat-Medium', sans-serif;
}

.ft-6 {
  font-family: 'Montserrat-SemiBold', sans-serif;
}

.ft-7 {
  font-family: 'Montserrat-Bold', sans-serif;
}

.ft-8 {
  font-family: 'Montserrat-ExtraBold', sans-serif;
}

.ft-9 {
  font-family: 'Montserrat-Black', sans-serif;
}

.center-container {
  display: flex;
  justify-content: center;
  margin: 10px;
}

.shared-container {
  max-width: 500px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.custom-input {
  font-size: 16px;
  width: 100%;
  padding: 8px 12px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.custom-button {
  background-color: #44b3b3;
  color: white;
  padding: 12px;
  margin: 10px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
  font-family: 'Montserrat-Bold', sans-serif;
}

.custom-button:hover {
  background-color: #44b3b3;
}

.link-button {
  color: #44b3b3;
  background-color: transparent;
  padding: 6px;
  margin: 6px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
  border: none;
  font-family: 'Montserrat-Bold', sans-serif;

  text-decoration: underline;
}

.link-space {
  justify-content: center;
  align-content: center;
  padding: 6px;
  margin: 6px 0;
}

.password-checklist {
  font-size: 0.9em;
  margin-top: 5px;
  color: #000;
}

.password-checklist ul {
  margin: 0;
  padding-left: 20px;
}

.password-checklist li {
  list-style: none;
  margin-bottom: 5px;
  color: inherit;
  margin: 3px 0px;
  padding: 0;
}

.password-checklist li.valid {
  font-weight: bold;
}

.error {
  color: red;
  font-size: 0.9em;
  margin-top: 5px;
}

.carousel {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #f6f6f6;
}

.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.carousel-text {
  position: relative;
  z-index: 2;
  color: white;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 10px;
  max-width: 600px;
  width: 80%;
  font-family: 'Montserrat-SemiBold', sans-serif;
  box-sizing: border-box;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.carousel-text.show {
  opacity: 1;
}

.carousel-text h2 {
  font-size: 2.5rem;
  margin-bottom: 5px;
}

.carousel-text h3 {
  font-size: 1.5rem;
  margin-top: 0;
  margin-bottom: 15px;
}

.carousel-text p {
  font-size: 1.2rem;
}

@media (max-width: 768px) {
  .carousel-text {
    max-width: 90%;
    padding: 15px;
  }

  .carousel-text h2 {
    font-size: 2rem;
  }

  .carousel-text h3 {
    font-size: 1.2rem;
  }

  .carousel-text p {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .carousel-text {
    max-width: 95%;
    padding: 10px;
  }

  .carousel-text h2 {
    font-size: 1.5rem;
  }

  .carousel-text h3 {
    font-size: 1rem;
  }

  .carousel-text p {
    font-size: 0.9rem;
  }
}
